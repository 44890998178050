<template>
        <div>
            <div class="columns is-mobile is-multiline">
                <div class="column is-full-mobile">
                    <div class="field">
                        <label class="label">{{ i18n('Title') }}</label>
                        <div class="control">
                            <input class="input" type="text" v-model="notificationUser.title"
                                   disabled>
                        </div>
                    </div>
                </div>

                <div class="column is-full-mobile">
                    <div class="field">
                        <label class="label">{{ i18n('Description') }}</label>
                        <div class="control">
                            <input class="input" type="text" v-model="notificationUser.description"
                                   disabled>
                        </div>
                    </div>
                </div>
        </div>
        </div>
</template>

<script>
export default {
    name: 'Show',
    inject: ['http', 'route', 'i18n'],
    data() {
        return {
            notificationUser: {
                tittle: '',
                description: '',
            },
        };
    },
    mounted() {
        this.fetchShow();
    },
    methods: {
        fetchShow() {
            this.http
                .get(this.route('notificationUser.show', { notificationUser: this.$route.params.notificationUser }))
                .then(({ data }) => {
                    this.notificationUser = data.notificationUser;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
</style>
